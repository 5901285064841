import React from "react";

export const HandshakeIcon = () => (
  <svg width={42} height={26}>
    <defs>
      <path id="handshake-icon__a" d="M.044.04h27.051v15.548H.044z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.504 21.455c.086.362.13.73.04 1.095-.207.838-.958 1.43-1.833 1.487a3.188 3.188 0 01-1.756-.385c-.012.242.06.452.024.67-.156.942-.887 1.586-1.863 1.634a2.828 2.828 0 01-1.375-.276c-.249-.118-.249-.119-.06-.305.808-.794 1.62-1.585 2.424-2.384 1.042-1.037 1.117-2.513.147-3.58-.722-.793-1.644-1.026-2.691-.754-.299.078-.296.086-.324-.224-.085-.949-.573-1.64-1.424-2.073-.689-.351-1.41-.371-2.129-.089-.189.074-.224.05-.233-.15-.05-1.22-1.152-2.293-2.393-2.352-.394-.019-.78.011-1.174.166-.006-.47-.069-.9-.265-1.304-.758-1.557-2.861-1.961-4.131-.772-.88.825-1.731 1.68-2.585 2.532-.152.153-.215.131-.343-.021-1.144-1.363-2.481-2.501-4.074-3.335-.05-.027-.1-.055-.152-.079-.398-.182-.397-.182-.223-.578l3.98-9.043A1.91 1.91 0 014.417.8C4.84.336 5.334.257 5.89.563c.267.147.482.355.705.555.593.53 1.318.754 2.089.855a7.608 7.608 0 002.842-.15c.7-.175 1.378-.44 2.08-.623 1.806-.47 3.634-.757 5.51-.661a8.208 8.208 0 011.862.303.77.77 0 01-.25.35c-1.63 1.447-3.255 2.896-4.888 4.338-.394.348-.815.665-1.164 1.062-1.273 1.45-.754 3.108.682 3.978a4.717 4.717 0 003.646.567c.402-.097.71-.347 1.029-.586 1.499-1.123 3.001-2.242 4.497-3.37.145-.11.238-.1.383-.008a35.797 35.797 0 016.428 5.208c.781.797 1.548 1.61 2.215 2.504.705.945.876 2.006.54 3.125-.253.84-1.056 1.43-1.945 1.495-.46.034-.915.01-1.35-.15-.186-.067-.224-.022-.21.16.1 1.23-.716 2.04-1.714 2.274-.694.163-1.36.06-2.005-.216-.112-.048-.221-.101-.332-.151l-.046-.019.01.052"
        fill="#FEFEFE"
      />
      <g transform="translate(14.867 .008)">
        <mask id="handshake-icon__b" fill="#fff">
          <use xlinkHref="#handshake-icon__a" />
        </mask>
        <path
          d="M9.208.04c.676.044 1.3.255 1.921.472 1.32.462 2.622.973 3.992 1.287 1.626.374 3.229.392 4.799-.245.457-.185.865-.431 1.206-.797.263-.283.605-.432 1.016-.336.295.069.47.24.569.53.426 1.256.989 2.458 1.53 3.667.634 1.415 1.28 2.826 2.021 4.191.173.319.362.625.603.903.376.432.28 1.005-.2 1.335-1.603 1.107-3.159 2.275-4.606 3.576a5.314 5.314 0 00-.56.604c-.365.448-.806.486-1.21.072-.28-.29-.518-.619-.771-.933A37.177 37.177 0 009.997 6.01c-.24-.148-.318-.043-.48.085-.594.466-1.205.908-1.81 1.36-1.148.86-2.297 1.72-3.444 2.581-.21.157-.45.234-.702.257-.872.078-1.707-.058-2.484-.471a2.068 2.068 0 01-.919-1c-.196-.429-.126-.845.105-1.243.222-.383.54-.685.878-.963 1.25-1.032 2.43-2.142 3.646-3.21.59-.518 1.176-1.041 1.764-1.562a1.55 1.55 0 00.267-.309C7.323.775 7.97.206 8.924.068c.096-.014.194-.019.284-.027"
          fill="#FEFEFE"
          mask="url(#handshake-icon__b)"
        />
      </g>
      <path
        d="M11.825 21.964c-.012-.64.16-1.032.482-1.351a343.758 343.758 0 013.169-3.112 1.655 1.655 0 012.347.018c.65.651.638 1.643-.035 2.307a829.132 829.132 0 01-3.15 3.096 1.636 1.636 0 01-1.807.346c-.653-.283-.979-.79-1.006-1.304m3.667 2.262c-.015-.452.163-.832.479-1.145a355.884 355.884 0 013.168-3.112 1.663 1.663 0 011.877-.32c.598.272.981.888.948 1.554-.021.415-.19.775-.49 1.07-1.056 1.038-2.11 2.077-3.168 3.113a1.671 1.671 0 01-1.808.353 1.601 1.601 0 01-1.006-1.513m-5.826-3.164c-.691-.022-1.202-.336-1.489-.95-.293-.628-.213-1.245.274-1.74a150.04 150.04 0 013.302-3.25c.652-.624 1.662-.562 2.304.093.607.62.604 1.62-.02 2.236a753.946 753.946 0 01-3.2 3.147 1.576 1.576 0 01-1.171.464m1.24-7.492c.001.622-.157.998-.47 1.307-1.066 1.05-2.131 2.101-3.203 3.146a1.655 1.655 0 01-2.294.003c-.65-.613-.705-1.608-.075-2.247a147.047 147.047 0 013.306-3.247c.504-.48 1.129-.54 1.772-.273.592.245.967.842.964 1.31m15.611 7.937l-.022-.103.098.037-.076.066"
        fill="#FEFEFE"
      />
    </g>
  </svg>
);
