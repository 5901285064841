import React, { FC, ReactNode, useState, useEffect } from "react";

type Props = {
  children: (props: {
    postalCode?: string;
    setPostalCode: (postalCode: string, callback: () => void) => void;

    mapZoom: number;
    setMapZoom: (mapZoom: number) => void;

    mapCenter: [number, number];
    setMapCenter: (mapCenter: [number, number]) => void;

    username: string;
    setUsername: (username: string) => void;

    password: string;
    setPassword: (password: string) => void;

    isExpired: boolean;
  }) => ReactNode;
};

export const PersistedData: FC<Props> = props => {
  const { children } = props;

  const [mapZoom, setMapZoom] = useState(
    Number(
      typeof localStorage !== "undefined" && localStorage.getItem("snv:mapZoom")
    ) || 8
  );

  const [mapCenter, setMapCenter] = useState<[number, number]>(
    JSON.parse(
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("snv:mapCenter")) ||
        "[6.753396, 52.705724]"
    )
  );

  const [postalCode, setPostalCode] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("snv:postalCode")) ||
      undefined
  );

  const [username, setUsernameRaw] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("snv:username")) ||
      ""
  );

  const [password, setPasswordRaw] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("snv:password")) ||
      ""
  );

  const [expiresAt, setExpiresAtRaw] = useState(
    (typeof localStorage !== "undefined" &&
      localStorage.getItem("snv:expiresAt")) ||
      ""
  );

  const [currentTimestamp, setCurrentTimestamp] = useState(
    Math.floor(Date.now() / 1000)
  );

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTimestamp(Math.floor(Date.now() / 1000));
    }, 10000);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    localStorage.setItem("snv:mapZoom", String(mapZoom));
    localStorage.setItem("snv:mapCenter", JSON.stringify(mapCenter));
  }, [mapZoom, mapCenter]);

  return (
    <>
      {children({
        postalCode,
        setPostalCode: (postalCode, callback) => {
          if (postalCode.trim().length) {
            fetch(
              `https://api.webba.nl/postcode/?postalcode=${postalCode.replace(
                /\s/g,
                ""
              )}`
            )
              .then(res => res.json())
              .then(v => {
                setPostalCode(v.postalcode);
                localStorage.setItem("snv:postalCode", v.postalcode);
                setMapZoom(14);
                setMapCenter([v.geo.center.long, v.geo.center.lat]);
                callback();
              });
          } else {
            callback();
          }
        },

        mapZoom,
        setMapZoom,

        mapCenter,
        setMapCenter,

        username,
        setUsername: username => {
          setUsernameRaw(username);
          localStorage.setItem("snv:username", username);

          const expiresAt = Math.floor(Date.now() / 1000) + 600;
          setExpiresAtRaw(expiresAt.toString());
          localStorage.setItem("snv:expiresAt", expiresAt.toString());
        },

        password,
        setPassword: password => {
          setPasswordRaw(password);
          localStorage.setItem("snv:password", password);
        },

        isExpired: currentTimestamp > Number(expiresAt),
      })}
    </>
  );
};
