import React, { FC } from "react";

export const MapIcon: FC = () => (
  <svg width={42} height={35}>
    <defs>
      <path id="map-icon__a" d="M0 .09h39.959v25.188H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 9.697)">
        <mask id="map-icon__b" fill="#fff">
          <use xlinkHref="#map-icon__a" />
        </mask>
        <path
          d="M0 19.076c.427-1.49.866-2.976 1.279-4.47C2.566 9.94 3.844 5.271 5.126.603c.042-.154.096-.305.16-.51.705.424 1.388.831 2.068 1.244 1.095.665 2.19 1.329 3.282 2 .568.35.733.824.47 1.296-.254.46-.873.607-1.4.297-1.087-.639-2.161-1.298-3.288-1.977l-4.295 15.55 7.27 4.448c.147-1.25.286-2.421.421-3.592l.825-7.205c.149-1.3.295-2.598.453-3.896.071-.584.49-.955.985-.906.5.05.875.484.82 1.061-.136 1.408-.308 2.812-.47 4.218-.267 2.33-.538 4.66-.807 6.99l-.38 3.279c.58-.183 1.082-.334 1.578-.498 1.985-.658 3.965-1.33 5.955-1.973.331-.107.444-.253.435-.6-.024-.861-.013-1.723-.007-2.585.004-.554.36-.942.863-.959.503-.017.93.402.937.96.013 1.022.004 2.044.004 3.091L28.918 23c-.045-.514-.074-.964-.126-1.41-.45-3.86-.905-7.72-1.355-11.579a30.922 30.922 0 01-.155-1.604c-.039-.597.3-1.012.826-1.056.496-.041.937.353 1 .91.434 3.807.863 7.615 1.298 11.422.115 1.009.244 2.016.38 3.13l7.09-4.309-4.293-15.55-2.865 1.724c-.183.11-.363.228-.553.323-.518.256-1.016.106-1.274-.373-.246-.455-.112-.935.38-1.237 1.531-.94 3.07-1.869 4.606-2.8.257-.157.518-.307.843-.5.232.814.46 1.59.673 2.37 1.506 5.496 3.005 10.994 4.524 16.486.098.352.028.505-.266.682-3.046 1.839-6.082 3.695-9.131 5.529a.928.928 0 01-.684.083c-3.2-1.046-6.393-2.117-9.59-3.173a.824.824 0 00-.484-.003c-3.198 1.056-6.39 2.126-9.59 3.175-.19.062-.472.049-.638-.051-3.185-1.92-6.358-3.857-9.534-5.79v-.323z"
          fill="#FEFEFE"
          mask="url(#map-icon__b)"
        />
      </g>
      <path
        d="M24.826 8.566c-.01 2.862-2.486 4.656-4.586 4.483-2.478-.003-4.454-2.011-4.446-4.52.007-2.456 2.054-4.497 4.51-4.5 2.485-.003 4.53 2.042 4.522 4.537m3.697-1.425C27.813 3.31 25.13.735 21.306.104c-3.67-.607-7.543 1.502-9.025 4.945-1.128 2.62-.971 5.276-.07 7.914.991 2.906 2.655 5.42 4.612 7.738 1.022 1.21 2.126 2.35 3.177 3.505.702-.683 1.352-1.266 1.946-1.903 2.36-2.537 4.405-5.295 5.73-8.543.868-2.131 1.27-4.34.847-6.619"
        fill="#FEFEFE"
      />
    </g>
  </svg>
);
