import React, { FC, useRef, useContext } from "react";
import { StoryFragment } from "../../graphql-types";
import Img, { FluidObject } from "gatsby-image";
import { ySpaceRem, colors } from "../global-style";
import { LayoutContext } from "./layout";
import { Link } from "gatsby";

type Props = { story: StoryFragment };

export const StoryPreview: FC<Props> = props => {
  const { remInPx } = useContext(LayoutContext);

  const featuredImageEl = useRef<HTMLDivElement>(null);

  const featuredImagePaddingYRem =
    (featuredImageEl.current &&
      ySpaceRem / 2 -
        ((featuredImageEl.current.getBoundingClientRect().height / remInPx) %
          (ySpaceRem / 2))) ||
    0;

  return (
    <div
      css={`
        transition: transform 0.2s ease;

        &:hover {
          transform: translateY(${-0.25 * ySpaceRem}rem);
        }
      `}
    >
      <Link
        to={`/${props.story.uri}`}
        title={`Lees het hele verhaal over "${props.story.title}"`}
      >
        {props.story.featuredImage?.node?.sourceUrl && (
          <div
            style={{
              paddingTop: `${0.5 * featuredImagePaddingYRem}rem`,
              paddingBottom: `${0.5 * featuredImagePaddingYRem}rem`,
              transition: "padding 0.25s ease",
            }}
          >
            <div ref={featuredImageEl}>
              <Img
                fluid={
                  props.story.featuredImage?.node?.localFile?.childImageSharp
                    ?.fluid as FluidObject
                }
                style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}
              />
            </div>
          </div>
        )}

        <p
          style={{
            fontSize: "0.8rem",
            textTransform: "uppercase",
            marginBottom: `${0.25 * ySpaceRem}rem`,
          }}
        >
          {props.story.storyCustomFields?.climateThemesStories
            ?.map(climateTheme => climateTheme?.title)
            .join(", ")}
        </p>

        <h2
          dangerouslySetInnerHTML={{ __html: props.story.title || "" }}
          style={{ marginBottom: `${0.25 * ySpaceRem}rem`, lineHeight: 1.5 }}
        />

        <div dangerouslySetInnerHTML={{ __html: props.story.excerpt || "" }} />

        <p style={{ textDecoration: "underline", color: colors.mediumBlue }}>
          Lees het hele verhaal.
        </p>
      </Link>
    </div>
  );
};
