import React, { FC } from "react";

export const SpeechBubbleIcon: FC = () => (
  <svg width={42} height={34}>
    <defs>
      <path
        d="M33.165 3.333c1.932 0 3.502 1.782 3.502 3.98V27.71c0 2.195-1.568 3.98-3.502 3.98H12.02c-.272 0-.68.133-.898.288l-6.177 4.399c-.224.16-.638.289-.896.289h-.225a.495.495 0 01-.49-.51V7.313c0-2.195 1.567-3.98 3.5-3.98h26.331zM11.667 15a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM20 15a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm8.333 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        id="speech-bubble-icon__a"
      />
    </defs>
    <use
      fill="#FFF"
      xlinkHref="#speech-bubble-icon__a"
      transform="translate(-3 -3)"
      fillRule="evenodd"
    />
  </svg>
);
