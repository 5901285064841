import * as React from "react";
import { FC } from "react";

type Props = {
  idPrefix: string; // developer may supply some arbitrary string to prevent duplicate id's
};

export const Caret: FC<Props> = props => {
  const { idPrefix } = props;

  return (
    <svg width={8} height={12} viewBox="0 0 8 12">
      <defs>
        <path id={`${idPrefix}caret__a`} d="M0 0h1440v251H0z" />
        <path id={`${idPrefix}caret__c`} d="M0 0h1440v251H0z" />
        <path
          id={`${idPrefix}caret__d`}
          d="M4.667 3.416L8.809 8l-4.142 4.584L5.94 14l5.393-6L5.94 2z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-591-914H849v3750H-591z" />
        <g transform="translate(-591 -134)">
          <mask id={`${idPrefix}caret__b`} fill="#fff">
            <use xlinkHref={`#${idPrefix}caret__a`} />
          </mask>
          <use fill="#3287C8" xlinkHref={`#${idPrefix}caret__a`} />
        </g>
        <use
          fill="#FFF"
          xlinkHref={`#${idPrefix}caret__d`}
          transform="translate(-4 -2)"
        />
      </g>
    </svg>
  );
};
