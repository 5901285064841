import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PollQuery } from "../../graphql-types";
import { ySpaceRem, colors, fontStacks, xSpaceRem } from "../global-style";

export const Poll = () => {
  const data = useStaticQuery<PollQuery>(graphql`
    query Poll {
      wp {
        poll {
          pollCustomFields {
            question
            options {
              option
            }
          }
        }
      }
    }
  `);

  // const [answeredQuestion, setAnsweredQuestion] = useState<string>();
  // const [answer, setAnswer] = useState<string>();

  const [results, setResults] = useState<
    { option: string; numberOfVotes: string }[]
  >([]);

  useEffect(() => {
    const answeredQuestion =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("snv:pollAnsweredQuestion")) ||
      "";

    if (
      answeredQuestion.trim() ===
      data.wp.poll?.pollCustomFields?.question?.trim()
    ) {
      fetch("https://cms.laatunietverrassen.nl/poll/index.php")
        .then(res => res.json())
        .then(data => {
          setResults(data);
        });
    }
  }, []);

  const totalNumberOfVotes = results.reduce(
    (acc, result) => acc + Number(result.numberOfVotes),
    0
  );

  return (
    <>
      <p style={{ marginBottom: `${ySpaceRem}rem` }}>
        {data.wp.poll?.pollCustomFields?.question}
      </p>

      {results.length ? (
        <div
          style={{ textAlign: "left", width: "100%" }}
          css={`
            & > * + * {
              margin-top: ${0.5 * ySpaceRem}rem;
            }
          `}
        >
          {results.map((result, i) => (
            <div key={i}>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginBottom: `${0.25 * ySpaceRem}rem`,
                }}
              >
                <span style={{ fontSize: "1.25rem", fontWeight: 700 }}>
                  {(
                    (Number(result.numberOfVotes) / totalNumberOfVotes) *
                    100
                  ).toFixed(1)}
                  %
                </span>
                <span
                  style={{
                    fontSize: "0.8rem",
                    marginLeft: `${0.5 * xSpaceRem}rem`,
                  }}
                >
                  {result.option}
                </span>
              </div>

              <div
                style={{
                  backgroundColor: colors.darkBlue,
                  height: `${0.25 * ySpaceRem}rem`,
                  width: "100%",
                  borderRadius: `${0.25 * ySpaceRem}rem`,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.orange,
                    height: `${0.25 * ySpaceRem}rem`,
                    width: `${(Number(result.numberOfVotes) /
                      totalNumberOfVotes) *
                      100}%`,
                    borderRadius: `${0.25 * ySpaceRem}rem`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            </div>
          ))}

          <p style={{ fontSize: "0.8rem" }}>
            Totaal aantal stemmen: {totalNumberOfVotes}
          </p>
        </div>
      ) : (
        <ul>
          {data.wp.poll?.pollCustomFields?.options?.map((option, i) => (
            <li key={i}>
              <button
                style={{
                  height: `${1.5 * ySpaceRem}rem`,
                  borderRadius: "1.5rem",
                  border: "none",
                  backgroundColor: colors.orange,
                  fontSize: "1rem",
                  fontWeight: 700,
                  fontFamily: fontStacks.univiaPro,
                  color: colors.white,
                  paddingLeft: `${3 * ySpaceRem}rem`,
                  paddingRight: `${3 * ySpaceRem}rem`,
                  ...(i !== 0 && {
                    marginTop: `${0.5 * ySpaceRem}rem`,
                  }),
                  cursor: "pointer",
                }}
                onClick={() => {
                  fetch("https://cms.laatunietverrassen.nl/poll/index.php", {
                    method: "POST",
                    mode: "no-cors",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ answer: option?.option }),
                  }).then(() => {
                    localStorage.setItem(
                      "snv:pollAnsweredQuestion",
                      data.wp.poll?.pollCustomFields?.question as string
                    );

                    fetch("https://cms.laatunietverrassen.nl/poll/index.php")
                      .then(res => res.json())
                      .then(data => {
                        setResults(data);
                      });
                  });
                }}
              >
                {option?.option}
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
