import React, { FC, useContext, useRef, CSSProperties, useState } from "react";
import { graphql } from "gatsby";
import { WavySection } from "../components/wavy-section";
import { colors, ySpaceRem, xSpaceRem, fontStacks } from "../global-style";
import { LayoutContext } from "../components/layout";
import { Center, centerGutterRem } from "../components/center";
import BackgroundImage, { IFluidObject } from "gatsby-background-image";
import { IndexQuery } from "../../graphql-types";
import { Grid } from "../components/grid";
import { Footer } from "../components/footer";
import Link, { navigate } from "gatsby-link";
import { PersistedData } from "../components/persisted-data";
import { StoryPreview } from "../components/story-preview";
import { Caret } from "../components/caret";
import { MapIcon } from "../components/map-icon";
import { SpeechBubbleIcon } from "../components/speech-bubble-icon";
import { HandshakeIcon } from "../components/handshake-icon";
import { SEO } from "../components/seo";
import { Poll } from "../components/poll";

export const query = graphql`
  query Index {
    heroBackground: file(relativePath: { eq: "hero-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }

    pollBackground: file(relativePath: { eq: "poll-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }

    wp {
      page(id: 639, idType: DATABASE_ID) {
        seo {
          ...SEO
        }
      }

      audiences {
        edges {
          node {
            uri
            title
          }
        }
      }

      stories {
        edges {
          node {
            ...Story
          }
        }
      }

      globaal {
        globalCustomFields {
          videoHomepage {
            url
            caption
          }
        }
      }
    }
  }
`;

const IndexPage: FC<{ data: IndexQuery }> = props => {
  const { remInPx, vwInPx, waveClearance } = useContext(LayoutContext);

  const [postalCodeInput, setPostalCodeInput] = useState({
    value: "",
    touched: false,
  });

  const heroOuterEl = useRef<HTMLDivElement>(null);
  const heroInnerEl = useRef<HTMLDivElement>(null);
  const pollOuterEl = useRef<HTMLDivElement>(null);
  const pollInnerEl = useRef<HTMLDivElement>(null);
  const grid2ndItemEl = useRef<HTMLDivElement>(null);

  const gridItemIconSizeRem = 1.5 * ySpaceRem;

  return (
    <>
      <SEO {...props.data.wp.page?.seo} />

      {(() => {
        const {
          presentationWidth: heroImageWidth,
          presentationHeight: heroImageHeight,
        } = props.data.heroBackground?.childImageSharp?.fluid as {
          presentationWidth: number;
          presentationHeight: number;
        };

        return (
          <div ref={heroOuterEl}>
            <BackgroundImage
              preserveStackingContext={true}
              fluid={
                props.data.heroBackground?.childImageSharp
                  ?.fluid as IFluidObject
              }
              style={{
                ...(vwInPx && {
                  maxHeight: `${(Math.ceil(
                    ((vwInPx * (heroImageHeight / heroImageWidth)) /
                      (ySpaceRem * remInPx)) *
                      2
                  ) /
                    2) *
                    ySpaceRem}rem`,
                }),
                transition: "max-height 0.25s ease",
              }}
              className="hero-bg"
            >
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  paddingTop: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
                  paddingBottom: `calc(${waveClearance} + ${2 * ySpaceRem}rem)`,
                  paddingLeft: `${gridItemIconSizeRem + centerGutterRem}rem`,
                  paddingRight: `${gridItemIconSizeRem + centerGutterRem}rem`,
                  transition: "padding 0.25s ease",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {(() => {
                  const gridHasSingleColumn =
                    !grid2ndItemEl.current ||
                    grid2ndItemEl.current.getBoundingClientRect().left -
                      centerGutterRem * remInPx ===
                      0;

                  const contentPadding = gridHasSingleColumn
                    ? `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`
                    : `${ySpaceRem}rem ${xSpaceRem}rem`;

                  const contentMaxWidth =
                    (grid2ndItemEl.current &&
                      grid2ndItemEl.current.getBoundingClientRect().width) ||
                    "25rem"; // fix magic number

                  const contentStyles: CSSProperties = {
                    padding: contentPadding,
                    boxSizing: "content-box",
                    maxWidth: contentMaxWidth,
                    transition: "padding 0.25s ease, max-width 0.25s ease",
                  };

                  return (
                    <div
                      ref={heroInnerEl}
                      style={{
                        backgroundColor: colors.white,
                        borderRadius: "1rem",
                        overflow: "hidden",
                        boxShadow: "0 0 14px 0 rgba(0,0,0,0.5)",
                      }}
                    >
                      <h2
                        style={{
                          ...contentStyles,
                          backgroundColor: colors.mediumBlue,
                          color: colors.white,
                          fontFamily: fontStacks.cubano,
                          fontSize: "2.5rem",
                          lineHeight: `${1.5 * ySpaceRem}rem`,
                          fontWeight: 500,
                        }}
                      >
                        Doe de
                        <br /> klimaatcheck!
                      </h2>

                      <div
                        style={{
                          ...contentStyles,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
                          Het klimaat verandert. Steeds vaker is er extreme
                          hitte, droogte of regenval. Bent u benieuwd naar de
                          mogelijke effecten hiervan in uw eigen omgeving? Vul
                          uw postcode in en bekijk de kaart.
                        </p>

                        <PersistedData>
                          {({ postalCode, setPostalCode }) => {
                            if (!postalCodeInput.touched && postalCode) {
                              setPostalCodeInput({
                                value: postalCode,
                                touched: true,
                              });
                            }

                            return (
                              <form
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                onSubmit={e => {
                                  e.preventDefault();
                                  setPostalCode(postalCodeInput.value, () => {
                                    navigate("/klimaatthemas/hitte");
                                  });
                                }}
                              >
                                <input
                                  value={postalCodeInput.value}
                                  onChange={e => {
                                    setPostalCodeInput({
                                      value: e.target.value,
                                      touched: true,
                                    });
                                  }}
                                  placeholder="Uw postcode"
                                  style={{
                                    width: "100%",
                                    marginBottom: `${0.5 * ySpaceRem}rem`,
                                    height: `${1.5 * ySpaceRem}rem`,
                                    borderRadius: "0.5rem",
                                    border: `2px solid ${colors.lightGrey}`,
                                    paddingLeft: `${0.5 * xSpaceRem}rem`,
                                    paddingRight: `${0.5 * xSpaceRem}rem`,
                                    fontSize: "1rem",
                                    fontFamily: fontStacks.univiaPro,
                                  }}
                                />

                                <button
                                  style={{
                                    borderRadius: "1.5rem",
                                    border: "none",
                                    backgroundColor: colors.orange,
                                    fontSize: "1rem",
                                    fontWeight: 700,
                                    fontFamily: fontStacks.univiaPro,
                                    color: colors.white,
                                    paddingTop: `${0.25 * ySpaceRem}rem`,
                                    paddingBottom: `${0.25 * ySpaceRem}rem`,
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  Bekijk de kaart &rarr;
                                </button>
                              </form>
                            );
                          }}
                        </PersistedData>
                      </div>
                    </div>
                  );
                })()}
              </div>
            </BackgroundImage>
          </div>
        );
      })()}

      <WavySection
        top="default"
        backgroundColor={colors.mediumBlue}
        style={{
          color: colors.white,
          position: "relative",
          zIndex: 1,
          paddingTop: `calc(${1.5 * ySpaceRem}rem + ${(() => {
            if (!heroOuterEl.current || !heroInnerEl.current) return 0;

            const outerBottom = heroOuterEl.current.getBoundingClientRect()
              .bottom;
            const innerBottom = heroInnerEl.current.getBoundingClientRect()
              .bottom;

            return Math.max(0, innerBottom - outerBottom);
          })()}px)`,
          paddingBottom: `${1.5 * ySpaceRem}rem`,
          transition: "padding 0.25s ease",
        }}
      >
        <Center>
          <Grid>
            <div style={{ display: "flex" }}>
              <div>
                <MapIcon />
              </div>
              <div
                style={{
                  marginLeft: `${0.5 * ySpaceRem}rem`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    marginBottom: `${0.5 * ySpaceRem}rem`,
                    fontFamily: fontStacks.cubano,
                    fontSize: "1.75rem",
                    lineHeight: `${1.5 * ySpaceRem}rem`,
                  }}
                >
                  Hitte, droogte, wateroverlast?
                </h3>
                <p style={{ marginTop: "auto" }}>
                  <Link
                    to="/klimaatthemas/hitte/"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <Caret idPrefix="climatethemes" />
                    <span style={{ marginLeft: `${0.25 * xSpaceRem}rem` }}>
                      Bekijk de effecten in uw buurt
                    </span>
                  </Link>
                </p>
              </div>
            </div>

            <div style={{ display: "flex" }} ref={grid2ndItemEl}>
              <div>
                <SpeechBubbleIcon />
              </div>
              <div
                style={{
                  marginLeft: `${0.5 * ySpaceRem}rem`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    marginBottom: `${0.5 * ySpaceRem}rem`,
                    fontFamily: fontStacks.cubano,
                    fontSize: "1.75rem",
                    lineHeight: `${1.5 * ySpaceRem}rem`,
                  }}
                >
                  Meepraten over effecten en maatregelen?
                </h3>
                <p style={{ marginTop: "auto" }}>
                  <a
                    href="#storm"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <Caret idPrefix="storm" />
                    <span style={{ marginLeft: `${0.25 * xSpaceRem}rem` }}>
                      {/*Storm heeft een paar vragen in de chat*/}
                      Beantwoord de vragen van Storm
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                style={{
                  position: "relative",
                  top: 8,
                }}
              >
                <HandshakeIcon />
              </div>
              <div
                style={{
                  marginLeft: `${0.5 * ySpaceRem}rem`,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    marginBottom: `${0.5 * ySpaceRem}rem`,
                    fontFamily: fontStacks.cubano,
                    fontSize: "1.75rem",
                    lineHeight: `${1.5 * ySpaceRem}rem`,
                  }}
                >
                  Over ons
                </h3>
                <p style={{ marginTop: "auto" }}>
                  <Link
                    to="/over-ons/"
                    style={{ display: "flex", alignItems: "baseline" }}
                  >
                    <Caret idPrefix="partners" />
                    <span style={{ marginLeft: `${0.25 * xSpaceRem}rem` }}>
                      De partners achter deze website
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </Grid>
        </Center>
      </WavySection>

      <div
        style={{
          paddingTop: `${1.5 * ySpaceRem}rem`,
          paddingBottom: `${1.5 * ySpaceRem}rem`,
        }}
      >
        <Center>
          <div
            style={{
              overflow: "hidden",
              marginBottom: `${1.5 * ySpaceRem}rem`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                margin: `-${0.5 * ySpaceRem}rem -${0.5 * xSpaceRem}rem`,
              }}
            >
              <div
                style={{
                  margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                  maxWidth: "none",
                  flexBasis: 0,
                  flexGrow: 1,
                }}
                css={`
                  & > p {
                    font-size: 1.1rem;
                  }

                  & > p + p {
                    margin-top: ${0.5 * ySpaceRem}rem;
                  }
                `}
              >
                <p>
                  Ons klimaat verandert. Een trampoline die naar de buren waait,
                  ondergelopen land door aanhoudende regen, irritaties van de
                  tijgermug of extra sterfte door hitte. De{" "}
                  <Link
                    to="/klimaatthemas/hitte/"
                    style={{
                      textDecoration: "underline",
                      color: colors.mediumBlue,
                    }}
                  >
                    effecten van extreem weer zijn in kaart gebracht
                  </Link>
                  . Dit raakt ons allemaal.
                </p>

                <p>
                  Het is belangrijk om in gesprek te gaan over de risico’s van
                  deze effecten en eventuele maatregelen. Overheden zijn al met
                  elkaar in gesprek. Maar ook op kleine schaal kunt u een
                  steentje bijdragen.
                </p>

                <p>
                  Laat u dus niet verrassen door extreem weer. Praat mee over
                  dit onderwerp!
                </p>
              </div>

              <div
                style={{
                  margin: `${0.5 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
                  ...(grid2ndItemEl.current && {
                    width: grid2ndItemEl.current.getBoundingClientRect().width,
                  }),
                  transition: "width 0.5s ease",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: `${0.5 * ySpaceRem}rem`,
                    fontSize: "1.1rem",
                  }}
                >
                  Informatie voor
                </p>

                <div style={{ overflow: "hidden" }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      margin: `-${0.25 * ySpaceRem}rem`,
                    }}
                  >
                    {props.data.wp.audiences?.edges?.map(
                      (edge, i) =>
                        edge?.node && (
                          <Link
                            key={i}
                            to={`/${edge.node.uri}`}
                            style={{
                              borderRadius: "1.5rem",
                              border: "none",
                              backgroundColor: colors.orange,
                              fontSize: "1rem",
                              fontFamily: fontStacks.univiaPro,
                              color: colors.white,
                              paddingLeft: `${0.5 * xSpaceRem}rem`,
                              paddingRight: `${0.5 * xSpaceRem}rem`,
                              paddingTop: `${0.25 * ySpaceRem}rem`,
                              paddingBottom: `${0.25 * ySpaceRem}rem`,
                              margin: `${0.25 * ySpaceRem}rem`,
                            }}
                          >
                            {edge.node.title}
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: `${1.5 * ySpaceRem}rem` }}>
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
                ...(props.data.wp.globaal?.globalCustomFields?.videoHomepage
                  ?.caption && { marginBottom: `${0.5 * ySpaceRem}rem` }),
              }}
            >
              <iframe
                title="Laat u niet verrassen - YouTube"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={
                  `${props.data.wp.globaal?.globalCustomFields?.videoHomepage?.url}?disablekb=1` ||
                  ""
                }
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
              />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {props.data.wp.globaal?.globalCustomFields?.videoHomepage
                ?.caption && (
                <p style={{ fontStyle: "italic" }}>
                  {
                    props.data.wp.globaal.globalCustomFields.videoHomepage
                      .caption
                  }
                </p>
              )}

              <p style={{ marginLeft: "0.5rem" }}>
                Gepubliceerd:{" "}
                <span style={{ whiteSpace: "nowrap" }}>28 mei 2020</span>
              </p>
            </div>
          </div>

          <h3
            style={{
              fontWeight: 700,
              marginBottom: `${0.5 * ySpaceRem}rem`,
              fontSize: "2rem",
              lineHeight: `${2 * ySpaceRem}rem`,
            }}
          >
            Verhalen over hitte, droogte en wateroverlast
          </h3>

          <Grid style={{ marginBottom: `${0.5 * ySpaceRem}rem` }}>
            {props.data.wp.stories?.edges
              ?.slice(0, 3)
              .map(
                (edge, i) =>
                  edge?.node && <StoryPreview key={i} story={edge.node} />
              )}
          </Grid>

          <Link
            to="/verhalen/"
            style={{
              borderRadius: "1.5rem",
              border: "none",
              backgroundColor: colors.orange,
              fontSize: "1rem",
              fontWeight: 700,
              fontFamily: fontStacks.univiaPro,
              color: colors.white,
              padding: `${0.25 * ySpaceRem}rem ${0.5 * xSpaceRem}rem`,
              marginBottom: `${1 * ySpaceRem}rem`,
              display: "inline-block",
            }}
          >
            Bekijk meer verhalen &rarr;
          </Link>
        </Center>
      </div>

      {(() => {
        const {
          presentationWidth: pollImageWidth,
          presentationHeight: pollImageHeight,
        } = props.data.pollBackground?.childImageSharp?.fluid as {
          presentationWidth: number;
          presentationHeight: number;
        };

        return (
          <div ref={pollOuterEl}>
            <BackgroundImage
              preserveStackingContext={true}
              fluid={
                props.data.pollBackground?.childImageSharp
                  ?.fluid as IFluidObject
              }
              style={{
                paddingLeft: `${xSpaceRem}rem`,
                paddingRight: `${xSpaceRem}rem`,
                ...(vwInPx && {
                  maxHeight: `${(Math.ceil(
                    ((vwInPx * (pollImageHeight / pollImageWidth)) /
                      (ySpaceRem * remInPx)) *
                      2
                  ) /
                    2) *
                    ySpaceRem}rem`,
                }),
                transition: "max-height 0.25s ease",
              }}
              className="poll-bg"
            >
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  paddingTop: `${5 * ySpaceRem}rem`,
                  paddingBottom: `calc(${waveClearance} + ${4 * ySpaceRem}rem)`,
                  transition: "padding 0.25s ease",
                }}
              >
                <div
                  ref={pollInnerEl}
                  style={{
                    backgroundColor: colors.mediumBlue,
                    color: colors.white,
                    maxWidth: "35ch",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: `${ySpaceRem}rem ${xSpaceRem}rem`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    borderRadius: "1rem",
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontSize: "1.5rem",
                      lineHeight: `${ySpaceRem}rem`,
                      marginBottom: `${0.5 * ySpaceRem}rem`,
                    }}
                  >
                    Poll
                  </p>

                  <Poll />
                </div>
              </div>
            </BackgroundImage>
          </div>
        );
      })()}

      <Footer
        style={{
          paddingTop: `calc(${1.5 * ySpaceRem}rem + ${(() => {
            if (!pollOuterEl.current || !pollInnerEl.current) return 0;
            const outerBottom = pollOuterEl.current.getBoundingClientRect()
              .bottom;
            const innerBottom = pollInnerEl.current.getBoundingClientRect()
              .bottom;
            return Math.max(0, innerBottom - outerBottom);
          })()}px)`,
        }}
      />
    </>
  );
};

export default IndexPage;
