import React, { FC, useContext, CSSProperties } from "react";
import ReactResizeDetector from "react-resize-detector";
import { LayoutContext } from "./layout";
import { ySpaceRem, xSpaceRem } from "../global-style";

export const Grid: FC<{ style?: CSSProperties }> = props => {
  const { style, children } = props;

  const { remInPx } = useContext(LayoutContext);

  const minItemWidthRem = 18; // TODO: fix magic number

  return (
    <ReactResizeDetector
      handleWidth={true}
      render={({ width }) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns:
              minItemWidthRem * remInPx > width
                ? "100%"
                : `repeat(auto-fill, minmax(${minItemWidthRem}rem, 1fr))`,
            gridGap: `${ySpaceRem}rem ${1 * xSpaceRem}rem`,
            ...style,
          }}
        >
          {children}
        </div>
      )}
    />
  );
};
